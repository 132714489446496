<template>
  <div class="correlations">
    <Correlations v-if="user" />
    <LoginMsg v-if="!user" />
  </div>
</template>

<script>
import Correlations from '@/components/Correlations'
import LoginMsg from '@/components/LoginMsg'

export default {
  name: 'CorrelationsView',
  components: {
    Correlations,
    LoginMsg
  },
  data () {
    return {
      user: this.$store.state.user
    }
  }
}
</script>
