<template>
  <div id="login">
    <a v-if="!user" :href="loginLink">Login</a>
    <a v-if="user" :href="logoutLink">Logout</a>
  </div>
  <div class="nav">
    <router-link to="/" class="logo">SimplyStandards</router-link>
    <router-link to="/correlations" v-if="user">Correlations</router-link>
    <router-link to="/standards" v-if="user">Standards</router-link>
  </div>
  <router-view/>
</template>

<script>
import axios from 'axios'

export default {
  name: 'app',
  data () {
    return {
      loginLink: 'https://simplystandards.flvs.net/.auth/login/aad?post_login_redirect_uri=' + window.location.href,
      logoutLink: 'https://simplystandards.flvs.net/.auth/logout?post_logout_redirect_uri=https://simplystandards.flvs.net',
      user: null
    }
  },
  methods: {
    async getUserList () {
      try {
        const body = JSON.stringify({ command: 'getUserList' })

        const options = {
          method: 'POST',
          header: { 'Content-Type': 'application/json' },
          body: body
        }

        let response
        if (window.location.host.includes('localhost')) {
          response = await fetch('http://localhost:8080/api/users?', options)
        } else {
          response = await fetch('https://simplystandards.flvs.net/api/users?', options)
        }

        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        return data.response.resources[0].users
      } catch (error) {
        console.error('Error fetching users:', error)
      }
    }
  },
  async mounted () {
    const userList = await this.getUserList()

    axios.get('https://simplystandards.flvs.net/.auth/me')
      .then(response => {
        const data = response.data.clientPrincipal
        if (data != null && userList.includes(data.userDetails)) {
          this.$store.commit('updateUser', data.userDetails)
          this.user = this.$store.state.user
          console.log('User logged in as ', this.$store.state.user)
        } else {
          console.log('User not validated')
        }
      })
      .catch(error => {
        alert(error)
      })
  }
}
</script>

<style lang="sass">
body
  margin: 0
  padding: 0
  width: 100%

#app
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50
  width: 100%

.nav
  background-color: #2675C9
  box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.35)
  color: #FFF
  font-weight: bold
  line-height: 80px
  width: 100%

  a
    border-bottom: 4px solid transparent
    color: #FFF
    margin: 0 10px
    padding: 0 20px
    text-decoration: none
    transition: border-bottom 300ms

  a.router-link-exact-active
    color: #FFF
    border-bottom: 4px solid #F6BF41

  a:hover
    border-bottom: 4px solid #F6BF41

  a.logo
    border: 4px solid #FFF
    font-size: 18px
    margin-right: 20px
    padding: 20px 10px

  a.logo:hover
    border: 4px solid #F6BF41

#login
  background-color: #1C355E
  color: #FFF
  cursor: pointer
  font-weight: bold
  line-height: 50px
  text-align: right
  width: 100%

  a
    color: #FFF
    padding: 14px 50px
    text-decoration: none

  a:hover
    background-color: #FFF
    color: #1C355E

.database
  margin: 0 auto
  width: 70%

.correlations
  margin: 0 auto
  width: 50%

.standards
  margin: 0 auto
  width: 50%

.standardsxlsx
  margin: 0 auto
  width: 50%
</style>
