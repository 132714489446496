<template>
  <div class="standards">
    <Standards v-if="user" />
    <LoginMsg v-if="!user" />
  </div>
</template>

<script>
import Standards from '@/components/Standards'
import LoginMsg from '@/components/LoginMsg.vue'

export default {
  name: 'StandardsView',
  components: {
    LoginMsg,
    Standards
  },
  data () {
    return {
      user: this.$store.state.user
    }
  }
}
</script>
