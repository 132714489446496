<template>
  <div>
    <p>Please log in to access this page.</p>
  </div>
</template>

<script>
export default {
  name: 'LoginMsg'
}
</script>

<style scoped lang="sass">
pre
  clear: left
  float: left
  text-align: left

button
  clear: left
  float: left
  margin: 20px auto

form
  font-family: Avenir, Helvetica, Arial, sans-serif
  margin: 0 0 20px
  text-align: left
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

h3
  margin: 0
  padding: 0
  text-align: left

#overlay
  background: rgba(255, 255, 255, 0.9)
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 960px
  visibility: hidden
  opacity: 0
  transition: opacity 2s ease-in-out, visibility 0s linear 2s

#overlay.show
  visibility: visible
  opacity: 1
  transition: opacity 500ms ease-in-out, visibility 0s linear

.abbrWrapper
  clear: left
  float: left
  margin: 20px 0 0
  width: 100%

.abbreviations
  border: 2px solid black
  height: 300px
  padding: 10px
  overflow-y: scroll
  text-align: left
  width: 100%

  .itemAbbr
    display: inline-block
    width: 20%

  .itemFullName
    display: inline-block
    width: 75%

.contentGroupWrapper
  float: left
  width: 100%

.content_groups
  border: 2px solid black
  padding: 10px
  overflow-y: scroll
  text-align: left
  background-color: white

.even-row
  background-color: #F0F0F0

.gradeLevelWrapper
  clear: left
  float: left
  margin: 20px 0 0
  width: 100%

.grade_levels
  display: inline-block
  border: 2px solid black
  height: 110px
  padding: 10px
  overflow-y: scroll
  text-align: left

.is-invalid
  border-color: red !important

.progress-bar
  background-color: white
  width: 60%
  height: 60px
  border: 2px solid #ccc
  margin: 20% auto 10px

.progress
  height: 100%
  background-color: #2675C9
  transition: width 0.3s ease

.subjectWrapper
  clear: left
  float: left
  margin: 20px 0 0
  width: 100%

.subjects
  border: 2px solid black
  padding: 10px
  overflow-y: scroll
  text-align: left

</style>
