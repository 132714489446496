<template>
  <div>
    <h1>Correlations</h1>

    <div id="overlay">
      <div class="progress-bar">
        <div class="progress" :style="{ width: progressBarWidth }"></div>
      </div>
      <div v-if="correlating" id="correlating">Correlating standards. This will take some time...</div>
      <div v-if="reportsWritten > 0" id="reports">{{ reportsWritten }} of {{ selectedAbbrs.length * selectedGradeLevels.length }} report<span v-if="selectedAbbrs.length * selectedGradeLevels.length > 1">s</span> created</div>
    </div>

    <form @submit.prevent="submitForm">
      <div class="contentGroupWrapper">
        <h3>Course:</h3>
        <select class="content_groups" v-model="selectedContentGroup" :class="{ 'is-invalid': errors.contentGroup }">
          <option v-for="(item) in content_groups" :key="item.id" :value="item.name">{{ item.name }}</option>
        </select>
      </div>

      <div class="subjectWrapper">
        <h3>Subject:</h3>
        <select class="subjects" v-model="selectedSubject" :class="{ 'is-invalid': errors.subject }">
          <option v-for="(item) in subjects" :key="item" :value="item">{{ item }}</option>
        </select>
      </div>

      <div class="gradeLevelWrapper">
        <h3>Grade level(s):</h3>
        <div class="grade_levels" :class="{ 'is-invalid': errors.gradeLevels }">
          <label>
            <input type="checkbox" v-model="selectAllGrades" @change="selectAllGradeLevels"/>
            <strong>Select All</strong>
          </label>
          <div v-for="(item, index) in grade_levels" :key="item" :class="{'even-row': index % 2 === 0}">
            <label>
              <input type="checkbox" v-model="selectedGradeLevels" :value="item"/>
              <span class="itemGradeLevel"> {{ item }} </span>
            </label>
          </div>
        </div>
      </div>

      <div class="abbrWrapper">
        <h3>Correlate with:</h3>
        <div class="abbreviations" :class="{ 'is-invalid': errors.abbrs }">
          <label>
            <input type="checkbox" v-model="selectAllAbbr" @change="selectAllAbbrs"/>
            <strong>Select All</strong>
          </label>
          <div v-for="(item, index) in abbrs" :key="item.id" :class="{'even-row': index % 2 === 0}">
            <label>
              <input type="checkbox" v-model="selectedAbbrs" :value="item.abbreviation"/>
              <span class="itemAbbr"> {{ item.abbreviation }} </span> <span class="itemFullName"> {{ item.fullName }} </span>
            </label>
          </div>
        </div>
      </div>

      <button type="submit">Create {{ selectedAbbrs.length * selectedGradeLevels.length }} report(s)</button>
    </form>
  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import JSZip from 'jszip'

const zip = new JSZip()
pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  name: 'Correlations',
  data () {
    return {
      abbrs: [],
      content_groups: [],
      correlatedStandardIDs: [],
      correlating: false,
      errors: {},
      grade_levels: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      loading: false,
      pdfContents: [],
      progress: 0,
      maxProgress: 100,
      reportsWritten: 0,
      selectedAbbrs: [],
      selectedGradeLevels: [],
      selectedContentGroup: '',
      selectedSubject: '',
      selectAllAbbr: false,
      selectAllGrades: false,
      subjects: [
        'Arts Education',
        'Career Development',
        'Career Technical Education',
        'Health and PE',
        'Language Arts',
        'Library Media',
        'Mathematics',
        'Science',
        'Social Studies',
        'Technology Education',
        'World Languages'
      ],
      zip: zip
    }
  },
  computed: {
    progressBarWidth () {
      return `${(this.progress / this.maxProgress) * 100}%`
    }
  },
  created () {
    this.getStandardsAbbrs()
    this.getContentGroups()
  },
  methods: {
    alphaNumericSort () {
      const courses = JSON.parse(JSON.stringify(this.content_groups))
      let modCourses = []

      courses.forEach(course => {
        if (!course.name.toLowerCase().includes('elementary')) return modCourses.push(course)

        if (!course.name.toLowerCase().includes(' k ')) return modCourses.push(course)

        course.name = course.name.replace(' K ', ' 0 ')
        modCourses.push(course)
      })

      const sortAlphaNum = (a, b) => a.name.localeCompare(b.name, 'en', { numeric: true })
      modCourses = modCourses.sort(sortAlphaNum)

      modCourses.forEach(course => {
        if (!course.name.toLowerCase().includes('elementary')) return

        if (!course.name.toLowerCase().includes(' 0 ')) return

        course.name = course.name.replace(' 0 ', ' K ')
      })

      this.content_groups = modCourses
    },

    generatePDF (data, totalLevels) {
      const state = this.abbrs.find(abbr => abbr.abbreviation === data.state)

      const content = {
        content: [
          { text: 'Main Criteria: ' + state.fullName, style: 'header', alignment: 'center' },
          { text: 'Secondary Criteria: ' + data.courseTitle, alignment: 'center' },
          { text: 'Subject: ' + data.subject, alignment: 'center' },
          { text: 'Grade: ' + data.grade, alignment: 'center' },
          { text: 'Adopted: ' + data.adopted, alignment: 'center' },
          { text: '\n\n' },
          this.generateStandards(data.standards, data.courseTitle, totalLevels)
        ],
        styles: {
          header: {
            fontSize: 10,
            bold: true
          }
        },
        defaultStyle: {
          fontSize: 8
        }
      }
      // const filename = state.abbreviation + '_' + data.courseTitle.replace(/ /g, '_') + '_' + Date.now() + '.pdf'
      // pdfMake.createPdf(content).download(filename)

      return new Promise((resolve) => {
        const pdfDoc = pdfMake.createPdf(content)
        pdfDoc.getBlob((blob) => {
          resolve(blob)
        })
      })
    },

    generateStandards (standards, courseTitle, totalLevels) {
      const result = []

      standards.forEach(standard => {
        let lessons = ''
        let course = ''
        let rowColor = ''

        if (standard.level === totalLevels.toString()) rowColor = '#FFFFFF'
        else if (standard.level === (totalLevels - 1).toString()) rowColor = '#EEEEEE'
        else if (standard.level === (totalLevels - 2).toString()) rowColor = '#DDDDDD'
        else if (standard.level === (totalLevels - 3).toString()) rowColor = '#CCCCCC'
        else if (standard.level === (totalLevels - 4).toString()) rowColor = '#BBBBBB'

        if (standard.lessons && standard.lessons.length > 0) {
          rowColor = '#FFFFFF'
          course = courseTitle

          for (let lessonIndex = 0; lessonIndex < standard.lessons.length; lessonIndex++) {
            lessons += standard.lessons[lessonIndex].title + '\n'
          }

          result.push({
            table: {
              widths: [65, 55, '*'],
              body: [
                [
                  {
                    table: {
                      body: [
                        [{ text: standard.levelDescription }]
                      ]
                    },
                    layout: 'noBorders'
                  },
                  {
                    table: {
                      body: [
                        [{ text: standard.label }]
                      ]
                    },
                    layout: 'noBorders'
                  },
                  {
                    table: {
                      body: [
                        [{ text: standard.description }],
                        [{ text: '\n' + course, decoration: 'underline' }],
                        [{ text: lessons }]
                      ]
                    },
                    layout: {
                      defaultBorder: false
                    }
                  }
                ]
              ],
              dontBreakRows: true
            },
            layout: {
              fillColor: rowColor
            },
            margin: 1
          })
        } else {
          result.push({
            table: {
              widths: [65, 55, '*'],
              body: [
                [
                  {
                    table: {
                      body: [
                        [{ text: standard.levelDescription }]
                      ]
                    },
                    layout: 'noBorders'
                  },
                  {
                    table: {
                      body: [
                        [{ text: standard.label }]
                      ]
                    },
                    layout: 'noBorders'
                  },
                  {
                    table: {
                      body: [
                        [{ text: standard.description }]
                      ]
                    },
                    layout: {
                      defaultBorder: false
                    }
                  }
                ]
              ],
              dontBreakRows: true
            },
            layout: {
              fillColor: rowColor
            },
            margin: 1
          })
        }

        if (standard.children && standard.children.length > 0) {
          result.push(...this.generateStandards(standard.children, courseTitle, totalLevels))
        }
      })

      return result
    },

    async getAllLearningObjects () {
      try {
        const body = JSON.stringify({ command: 'getData' })

        const options = {
          method: 'POST',
          header: { 'Content-Type': 'application/json' },
          body: body
        }

        let response
        if (window.location.host.includes('localhost')) {
          response = await fetch('http://localhost:8080/api/learning_objects?', options)
        } else {
          response = await fetch('https://simplystandards.flvs.net/api/learning_objects?', options)
        }

        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        return data.response.resources
      } catch (error) {
        console.error('Error fetching content groups:', error)
      }
    },

    async getContentGroups () {
      try {
        const body = JSON.stringify({ command: 'getData' })

        const options = {
          method: 'POST',
          header: { 'Content-Type': 'application/json' },
          body: body
        }

        let response
        if (window.location.host.includes('localhost')) {
          response = await fetch('http://localhost:8080/api/content_group?', options)
        } else {
          response = await fetch('https://simplystandards.flvs.net/api/content_group?', options)
        }

        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        this.content_groups = data.response.resources

        this.alphaNumericSort()
      } catch (error) {
        console.error('Error fetching content groups:', error)
      }
    },

    async getCrossReferencesByStandardId (stdId) {
      try {
        const body = JSON.stringify({ command: 'getCrossReferencesByStandardId', stdId: stdId })

        const options = {
          method: 'POST',
          header: { 'Content-Type': 'application/json' },
          body: body
        }

        let response
        if (window.location.host.includes('localhost')) {
          response = await fetch('http://localhost:8080/api/cross_references?', options)
        } else {
          response = await fetch('https://simplystandards.flvs.net/api/cross_references?', options)
        }

        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        return data.response
      } catch (error) {
        console.error('Error fetching cross references:', error)
      }
    },

    async getCrossRefCount (stdId) {
      try {
        const body = JSON.stringify({ command: 'getCrossRefCount', stdId: stdId })

        const options = {
          method: 'POST',
          header: { 'Content-Type': 'application/json' },
          body: body
        }

        let response
        if (window.location.host.includes('localhost')) {
          response = await fetch('http://localhost:8080/api/cross_references?', options)
        } else {
          response = await fetch('https://simplystandards.flvs.net/api/cross_references?', options)
        }

        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        return data
      } catch (error) {
        console.error('Error fetching cross references:', error)
      }
    },

    getFormattedDateTime () {
      const now = new Date()
      const year = now.getFullYear()
      const month = String(now.getMonth() + 1).padStart(2, '0')
      const day = String(now.getDate()).padStart(2, '0')
      const hours = String(now.getHours()).padStart(2, '0')
      const minutes = String(now.getMinutes()).padStart(2, '0')
      const seconds = String(now.getSeconds()).padStart(2, '0')
      return `${year}-${month}-${day}_${hours}${minutes}${seconds}`
    },

    async getStandardsByStateAndGrade (state, grade) {
      try {
        const body = JSON.stringify({ command: 'getStandardsByStateAndGrade', subject: this.selectedSubject, grade: grade, state: state })

        const options = {
          method: 'POST',
          header: { 'Content-Type': 'application/json' },
          body: body
        }

        let response
        if (window.location.host.includes('localhost')) {
          response = await fetch('http://localhost:8080/api/standards?', options)
        } else {
          response = await fetch('https://simplystandards.flvs.net/api/standards?', options)
        }

        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        return data.response
      } catch (error) {
        console.error('Error fetching learning objects:', error)
      }
    },

    async getStandardsAbbrs () {
      try {
        const body = JSON.stringify({ command: 'getData' })

        const options = {
          method: 'POST',
          header: { 'Content-Type': 'application/json' },
          body: body
        }

        let response
        if (window.location.host.includes('localhost')) {
          response = await fetch('http://localhost:8080/api/standard_abbr?', options)
        } else {
          response = await fetch('https://simplystandards.flvs.net/api/standard_abbr?', options)
        }

        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        this.abbrs = data.response.resources
      } catch (error) {
        console.error('Error fetching abbreviations:', error)
      }
    },

    async getStandardLevels (abbreviation) {
      try {
        const body = JSON.stringify({ command: 'getLevelsByAbbr', abbreviation: abbreviation })

        const options = {
          method: 'POST',
          header: { 'Content-Type': 'application/json' },
          body: body
        }

        let response
        if (window.location.host.includes('localhost')) {
          response = await fetch('http://localhost:8080/api/standard_levels?', options)
        } else {
          response = await fetch('https://simplystandards.flvs.net/api/standard_levels?', options)
        }

        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        return data.response.resources
      } catch (error) {
        console.error('Error fetching abbreviations:', error)
      }
    },

    async hasLessons (obj) {
      for (const key in obj) {
        if (key === 'lessons' && Array.isArray(obj[key]) && obj[key].length > 0) {
          return true
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          if (await this.hasLessons(obj[key])) return true
        }
      }
      return false
    },

    validateForm () {
      this.errors = {}

      if (!this.selectedContentGroup) this.errors.contentGroup = true
      if (!this.selectedSubject) this.errors.subject = true
      if (this.selectedGradeLevels.length === 0) this.errors.gradeLevels = true
      if (this.selectedAbbrs.length === 0) this.errors.abbrs = true

      return Object.keys(this.errors).length === 0
    },

    async submitForm () {
      if (!this.validateForm()) {
        console.log('Form not valid')
      } else {
        this.correlating = true
        const formattedDateTime = this.getFormattedDateTime()

        this.zip = new JSZip()

        this.progress = 0
        this.reportsWritten = 0

        // Setting window height for the loading overlay
        const windowHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight, document.documentElement.clientHeight).toString()
        const overlay = document.getElementById('overlay')
        overlay.style.height = windowHeight + 'px'
        overlay.classList.add('show')

        const selectedContentGroupID = this.content_groups.filter(contentGroup => contentGroup.name === this.selectedContentGroup)
        const learningObjects = await this.getAllLearningObjects()
        this.pdfContents = []

        // Parse the JSON, get a PDF, and put it in a zip file
        for (let stateIndex = 0; stateIndex < this.selectedAbbrs.length; stateIndex++) {
          // For each of the selected states...
          const standardLevels = await this.getStandardLevels(this.selectedAbbrs[stateIndex])

          // Get the standards for each grade level
          for (let gradeIndex = 0; gradeIndex < this.selectedGradeLevels.length; gradeIndex++) {
            // Get the standards for this selected state and grade
            const currentCourseStandards = []
            const seenIds = []
            const standards = await this.getStandardsByStateAndGrade(this.selectedAbbrs[stateIndex], this.selectedGradeLevels[gradeIndex])
            const levelCount = standards[0].level

            const courseJSON = {
              courseTitle: this.selectedContentGroup,
              subject: this.selectedSubject,
              grade: this.selectedGradeLevels[gradeIndex],
              state: this.selectedAbbrs[stateIndex]
            }

            // Starting at the lowest level, go through each standard and add it to its parent
            for (let levelIndex = standardLevels.length; levelIndex > 0; levelIndex--) {
              const levelStandards = standards.filter(standard => standard.level === levelIndex.toString())

              for (let lsIndex = 0; lsIndex < levelStandards.length; lsIndex++) {
                const childStandard = levelStandards[lsIndex]
                const parentStandard = standards.find(parent => parent.stdId === childStandard.parentStdId)

                childStandard.levelDescription = standardLevels.find(standardLevel => standardLevel.level === childStandard.level).description

                if (childStandard.parentStdId !== '0') {
                  const crossRefs = await this.getCrossReferencesByStandardId(childStandard.stdId)

                  if (typeof crossRefs.resources[0] !== 'undefined') {
                    for (let lessonIndex = 0; lessonIndex < crossRefs.resources[0].edgateLOIDs.length; lessonIndex++) {
                      const lesson = learningObjects.find(learningObject => learningObject.edgateLOID === crossRefs.resources[0].edgateLOIDs[lessonIndex])

                      if (lesson && lesson.contentGroupID === selectedContentGroupID[0].id) childStandard.lessons.push(lesson)
                    }
                  }

                  childStandard.lessons.sort((a, b) => a.title.localeCompare(b.title))

                  const hasLessons = await this.hasLessons(childStandard)
                  if (hasLessons) parentStandard.children.push(childStandard)

                  parentStandard.children.sort((a, b) => a.label.localeCompare(b.label))

                  // If we're at the top level and this stdId hasn't already been added, add it to currentCourseStandards
                  if (parentStandard.level === '1' && !seenIds.includes(parentStandard.stdId)) {
                    currentCourseStandards.push(parentStandard)
                    seenIds.push(parentStandard.stdId)
                  }
                }
              }
            }
            // console.log(currentCourseStandards)
            courseJSON.standards = currentCourseStandards
            courseJSON.adopted = currentCourseStandards[0].modDate

            await this.generatePDF(courseJSON, levelCount).then((pdfBlob) => {
              this.zip.file(courseJSON.state + '_' + courseJSON.courseTitle.replace(/ /g, '_') + '_Grade' + courseJSON.grade + '_' + formattedDateTime + '.pdf', pdfBlob)
            })

            this.reportsWritten++
            this.progress = this.reportsWritten
            this.maxProgress = (this.selectedAbbrs.length * this.selectedGradeLevels.length)
          }
        }

        this.correlating = false
        setTimeout(() => { this.progress = 0 }, 3000)

        // Create and download the zip file
        this.zip.generateAsync({ type: 'blob' }).then((content) => {
          const zipFile = new Blob([content])

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(zipFile)
          link.download = 'Correlations_' + formattedDateTime + '.zip'
          link.click()
          overlay.classList.remove('show')
        })
      }
    },

    selectAllAbbrs () {
      if (this.selectAllAbbr) {
        this.selectedAbbrs = this.abbrs.map(item => item.id)
      } else {
        this.selectedAbbrs = []
      }
    },

    selectAllGradeLevels () {
      if (this.selectAllGrades) {
        this.selectedGradeLevels = this.grade_levels.map(item => item)
      } else {
        this.selectedGradeLevels = []
      }
    }
  }
}
</script>

<style scoped lang="sass">
pre
  clear: left
  float: left
  text-align: left

button
  clear: left
  float: left
  margin: 20px auto

form
  font-family: Avenir, Helvetica, Arial, sans-serif
  margin: 0 0 20px
  text-align: left
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

h3
  margin: 0
  padding: 0
  text-align: left

#overlay
  background: rgba(255, 255, 255, 0.9)
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 960px
  visibility: hidden
  opacity: 0
  transition: opacity 2s ease-in-out, visibility 0s linear 2s

#overlay.show
  visibility: visible
  opacity: 1
  transition: opacity 500ms ease-in-out, visibility 0s linear

.abbrWrapper
  clear: left
  float: left
  margin: 20px 0 0
  width: 100%

.abbreviations
  border: 2px solid black
  height: 300px
  padding: 10px
  overflow-y: scroll
  text-align: left
  width: 100%

  .itemAbbr
    display: inline-block
    width: 20%

  .itemFullName
    display: inline-block
    width: 75%

.contentGroupWrapper
  float: left
  width: 100%

.content_groups
  border: 2px solid black
  padding: 10px
  overflow-y: scroll
  text-align: left
  background-color: white

.even-row
  background-color: #F0F0F0

.gradeLevelWrapper
  clear: left
  float: left
  margin: 20px 0 0
  width: 100%

.grade_levels
  display: inline-block
  border: 2px solid black
  height: 110px
  padding: 10px
  overflow-y: scroll
  text-align: left

.is-invalid
  border-color: red !important

.progress-bar
  background-color: white
  width: 60%
  height: 60px
  border: 2px solid #ccc
  margin: 20% auto 10px

.progress
  height: 100%
  background-color: #2675C9
  transition: width 0.3s ease

.subjectWrapper
  clear: left
  float: left
  margin: 20px 0 0
  width: 100%

.subjects
  border: 2px solid black
  padding: 10px
  overflow-y: scroll
  text-align: left

</style>
