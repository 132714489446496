import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CorrelationsView from '../views/CorrelationsView.vue'
import StandardsView from '../views/StandardsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/correlations',
    name: 'correlations',
    component: CorrelationsView
  },
  {
    path: '/standards',
    name: 'standards',
    component: StandardsView
  }
]

const router = createRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
