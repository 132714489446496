<template>
  <div>
    <h1 class="logo">SimplyStandards</h1>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style lang="sass" scoped>
.logo
  border: 6px solid #2675C9
  box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.35)
  color: #2675C9
  cursor: default
  font-size: 48px
  margin: 20px auto
  padding: 20px 10px
  width: 30%
</style>
