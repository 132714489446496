import { createStore } from 'vuex'

export default createStore({
  state () {
    return {
      user: null
    }
  },
  getters: {
  },
  mutations: {
    updateUser (state, payload) {
      state.user = payload // Update the variable with payload value
    }
  },
  actions: {
  },
  modules: {
  }
})
